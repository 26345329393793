<template>
	<div class="WithdrawPayAccount">
		
		<div class="Top">
			<div class="Left">
				打款账号详情
			</div>
			<div class="Right">
				<span>
					<el-button type="primary" icon="el-icon-arrow-left" @click="$router.go(-1)">返回打款账号列表</el-button>
				</span>
			</div>
		</div>
		
		<div class="List">
			
			<div class="One">
				<li v-if="WithdrawPayAccount != null && WithdrawPayAccount.Id != ''">
					<span>打款账号ID</span>
					<em>
						{{WithdrawPayAccount.Id}}
					</em>
				</li>
				
				<li>
					<span>打款账号通道</span>
					<em>
						<el-select :disabled="FormDisabled" v-model="WithdrawPayAccount.Type">
							<el-option :label="'银行卡'" :value="'bank'"></el-option>
							<el-option :label="'支付宝'" :value="'alipay'"></el-option>
							<el-option :label="'微信'" :value="'wechat'"></el-option>
						</el-select>
					</em>
				</li>
				
				<li v-if="WithdrawPayAccount.Type == 'bank'">
					<span>所属银行</span>
					<em>
						<el-select :disabled="FormDisabled"  v-model="WithdrawPayAccount.BankName">
							<el-option :label="item" :value="item" v-for="(item,itemI) in BankList" :key="itemI"></el-option>
						</el-select>
					</em>
				</li>
				
				<li>
					<span>所属主体</span>
					<em>
						<span style="display: inline-block;width:200px">
							<el-input :disabled="FormDisabled"  v-model="WithdrawPayAccount.Holder"></el-input>
						</span>
					</em>
				</li>
				
				<li>
					<span>账号</span>
					<em>
						<span style="display: inline-block;width:500px">
							<el-input :disabled="FormDisabled"  v-model="WithdrawPayAccount.No"></el-input>
						</span>
					</em>
				</li>
				
				<li>
					<span>使用状态</span>
					<em>
						<el-select :disabled="FormDisabled"  v-model="WithdrawPayAccount.Status">
							<el-option :label="'使用中'" :value="50"></el-option>
							<el-option :label="'禁用'" :value="10"></el-option>
						</el-select>
					</em>
				</li>
				
				
				<li v-if="WithdrawPayAccount.Id != ''">
					<span>创建时间</span>
					<em>
						{{WithdrawPayAccount.CreatedAt}}
					</em>
				</li>
				
				<li v-if="WithdrawPayAccount.Id != ''">
					<span>最后修改时间</span>
					<em>
						{{WithdrawPayAccount.UpdatedAt}}
					</em>
				</li>
				
				<li>
					<span></span>
					<em>
						<el-button v-if="WithdrawPayAccount.Id == ''"  @click="UpdateWithdrawPayAccount()" type="danger">创建打款账号</el-button>
						
						<el-button v-if="WithdrawPayAccount.Id != ''"  @click="UpdateWithdrawPayAccount()" type="danger">修改状态</el-button>
						
					</em>
				</li>
			</div>
		</div>
		
		
	</div>
</template>

<script>
	import {Select,Option,Dialog,Upload} from 'element-ui'
	export default {
	  name: 'WithdrawPayAccountInfo',
	  props: {
	  },
	  data() {
	      return {
			  WithdrawPayAccount:{
				  Id:'',
				  Type:'',
				  TypeName:'',
				  BankName:'',
				  Holder:'',
				  Status:50,
				  No:'',
				  StatusName:'',
				  CreatedAt:'',
				  UpdatedAt:'',
			  },
			  FormDisabled:false,
			  BankList:[
			  				  '中国工商银行',
			  				  '中国建设银行',
			  				  '中国农业银行',
			  				  '中国邮政储蓄银行',
			  				  '中国银行',
			  				  '交通银行',
			  				  '招商银行',
			  				  '中国光大银行',
			  				  '浦发银行',
			  				  '华夏银行',
			  				  '广发银行',
			  				  '中信银行',
			  				  '兴业银行',
			  				  '中国民生银行',
			  				  '杭州银行',
			  				  '上海银行',
			  				  '宁波银行',
			  				  '平安银行',
			  				  '上海农村商业银行',
			  				  '鞍山银行',
			  				  '北京农村商业银行',
			  				  '北京银行',
			  				  '渤海银行',
			  				  '常熟农商银行',
			  				  '长沙银行',
			  				  '成都银行',
			  				  '浙江稠州商业银行',
			  				  '东莞银行',
			  				  '大连银行',
			  				  '德阳银行',
			  				  '东莞农村商业银行',
			  				  '东营银行',
			  				  '富滇银行',
			  				  '福建海峡银行',
			  				  '广东省农村信用社联合社',
			  				  '广州银行',
			  				  '桂林银行',
			  				  '贵阳银行',
			  				  '河北银行',
			  				  '汉口银行',
			  				  '徽商银行',
			  				  '恒丰银行',
			  				  '湖州银行',
			  				  '嘉兴银行',
			  				  '江苏省农村信用社联合社',
			  				  '江苏银行',
			  				  '晋城银行',
			  				  '晋中市商业银行',
			  				  '辽阳市商业银行',
			  				  '龙江银行',
			  				  '南昌银行',
			  				  '南海农商银行',
			  				  '南京银行',
			  				  '宁夏银行',
			  				  '内蒙古银行',
			  				  '齐商银行',
			  				  '青岛银行',
			  				  '绍兴银行',
			  				  '顺德农商银行',
			  				  '苏州银行',
			  				  '天津银行',
			  				  '台州银行',
			  				  '温州银行',
			  				  '乌鲁木齐市商业银行',
			  				  '吴江农村商业银行',
			  				  '浙江民泰商业银行',
			  				  '浙江省农村信用社联合社',
			  				  '浙商银行',
			  				  '重庆银行',
			  				  '浙江泰隆商业银行',
			  				  '自贡市商业银行',
			  				  '昆仑银行',
			  				  '华融湘江银行',
			  				  '云南省农村信用社',
			  				  '重庆农村商业银行',
			  				  '库尔勒商业银行',
			  				  '张家港农村商业银行',
			  				  '成都农商银行',
			  				  '莱商银行',
			  				  '上饶银行',
			  				  '天津农商银行',
			  				  '江南农村商业银行',
			  				  '鄂尔多斯银行',
			  				  '邢台银行',
			  				  '晋商银行',
			  				  '九江商业银行',
			  				  '吉林省农村信用社',
			  				  '江苏太仓农村商业银行',
			  				  '营口银行',
			  				  '遵义市商业银行',
			  				  '山东省农村信用社联合社',
			  				  '驻马店银行',
			  				  '赣州银行',
			  				  '西安银行',
			  				  '广西北部湾银行',
			  				  '无锡农村商业银行',
			  				  '广州农商银行',
			  				  '江苏江阴农村商业银行',
			  				  '平顶山银行',
			  				  '南充市商业银行',
			  				  '重庆三峡银行',
			  				  '韩亚银行',
			  				  '河北省农村信用社',
			  				  '宁夏黄河农村商业银行',
			  				  '阜新银行',
			  				  '新乡银行',
			  				  '中山小榄村镇银行',
			  				  '贵州省农村信用社',
			  				  '邯郸银行',
			  				  '锦州银行',
			  				  '乐山市商业银行',
			  				  '安徽农金安阳市商业银行',
			  				  '泰安市商业银行',
			  				  '鄞州银行',
			  				  '齐鲁银行',
			  				  '渣打银行',
			  				  '四川省农村信用社',
			  				  '三门峡银行',
			  				  '德州银行',
			  				  '周口银行',
			  				  '深圳农村商业银行',
			  				  '包商银行',
			  				  '青海银行',
			  				  '兰州银行',
			  				  '丹东银行',
			  				  '金华银行',
			  				  '朝阳银行',
			  				  '临商银行',
			  				  '广西省农村信用社',
			  				  '承德银行',
			  				  '郑州银行',
			  				  '盛京银行',
			  				  '玉溪市商业银行',
			  				  '抚顺银行',
			  				  '尧都农村商业银行',
			  				  '上海银行',
			  				  '阳泉市商业银行',
			  				  '湖北银行',
			  				  '潍坊银行',
			  				  '威海市商业银行',
			  				  '开封市商业银行',
			  				  '济宁银行',
			  				  '甘肃省农村信用社',
			  				  '湖南省农村信用社',
			  				  '江西省农村信用社',
			  				  '许昌银行',
			  				  '张家口市商业银行',
			  				  '信阳银行',
			  				  '河南省农村信用社',
			  				  '武汉农村商业银行',
			  				  '陕西信合宜宾市商业银行',
			  				  '湖北省农村信用社',
			  				  '攀枝花市商业银行',
			  				  '昆山农村商业银行',
			  				  '柳州银行',
			  				  '衡水市商业银行',
			  				  '石嘴山银行',
			  				  '南阳村镇银行',
			  				  '长治市商业银行',
			  				  '日照银行',
			  				  '汇丰银行',
			  				  '（中国）星展银行',
			  				  '（中国）乌海银行',
			  				  '商丘银行',
			  				  '新韩银行',
			  				  '广东华兴银行',
			  				  '哈尔滨银行',
			  				  '厦门银行',
			  				  '福建农村信用社农商银行',
			  				  '广东南粤银行',
			  				  '珠海华润银行',
			  				  '鹤壁银行',
			  				  '渤海银行',
			  				  '广西北部湾银行',
			  				  '天津滨海农村商业银行',
			  				  '吉林银行',
			  				  '漯河银行',
			  				  '其它银行',
			  ],
	      }
	  },
	  components: {
		'el-select':Select,
		'el-option':Option,
		'el-dialog':Dialog,
		'el-upload':Upload,
	  },
	  created() {
	  	if(this.$route.params.Id == undefined){
			this.$message('缺少打款账号ID，页面无法工作')
			return
		}
		this.WithdrawPayAccount.Id = this.$route.params.Id
		if(this.WithdrawPayAccount.Id != 'add'){
			this.FormDisabled = true
			this.GetWithdrawPayAccount(this.$route.params.Id)
		}else{
			this.WithdrawPayAccount.Id = ''
		}
	  },
	  methods:{
		    GetWithdrawPayAccount(_id){
		  		let data = {Service:'Finance',Class: 'FinanceWithdrawPayAccount',Action: 'Get',Id:_id,}
		  		this.$post(this.$store.getters.getApiHost,data)
		  		.then((res) => {
		  			
		  			if(res.ErrorId != 0){
		  				this.$message(res.Msg)
		  				return
		  			}
		  			this.WithdrawPayAccount = res.Data
		  		})
		    },
			UpdateWithdrawPayAccount(){
				let data = {Service:'Finance',Class: 'FinanceWithdrawPayAccount',Action: 'Update',Id:this.WithdrawPayAccount.Id,Type:this.WithdrawPayAccount.Type,Status:this.WithdrawPayAccount.Status,Holder:this.WithdrawPayAccount.Holder,BankName:this.WithdrawPayAccount.BankName,No:this.WithdrawPayAccount.No}
				
				if(this.WithdrawPayAccount.Id == ''){
					data.Action = 'Add'
					data.Id = ''
				}
				this.$post(this.$store.getters.getApiHost,data)
				.then((res) => {
					
					this.$message(res.Msg)
					if(res.ErrorId == 0){
						this.WithdrawPayAccount.Id = res.Data.Id
						this.GetWithdrawPayAccount(this.WithdrawPayAccount.Id)
					}
	
				})
			},
			
	  }
	}
</script>

<style scoped>
.WithdrawPayAccount{
	background-color: #FFFFFF;
	margin: 20px;
	padding: 20px;
}
.WithdrawPayAccount .Top{
	display: flex;
	align-items: center;
}
.WithdrawPayAccount .Top .Left{
	
}
.WithdrawPayAccount .Top .Right{
	flex: 1;
	text-align: right;
	color: #999999;
}

.WithdrawPayAccount .List{
	margin-top: 20px;	
}
.WithdrawPayAccount .List .One{
	border: 1px solid rgba(0,0,0,0.04);
	padding: 20px;
	margin-bottom: 20px;
}
.WithdrawPayAccount .List .One li{
	display: flex;
	line-height: 20px;
	padding: 10px 0px;
	align-items: center;
}
.WithdrawPayAccount .List .One li span{
	width: 140px;
	color: rgba(0,0,0,0.3);
}
.WithdrawPayAccount .List .One li  em{
	flex: 1;
}
.WithdrawPayAccount .List .One li i{
	font-style: normal;
	color: rgba(0,0,0,0.2);
}
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 64px;
    height: 64;
    line-height: 64px;
    text-align: center;
  }
  .avatar {
    width: 64px;
    height: 64px;
    display: block;
  }
</style>
